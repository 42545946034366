// Type for country translations
type CountryTranslations = {
    [key: string]: {
      [lang: string]: string;
    };
  };
  
  // Define country translations
  const countryTranslations: CountryTranslations = {
    "Italia": {
      "en": "Italy",
      "fr": "Italie",
      "de": "Italien",
      "es": "Italia",
      "it": "Italia",
      "pt": "Itália",
      "nl": "Italië",
      "da": "Italien",
      "sv": "Italien",
      "fi": "Italia",
      "el": "Ιταλία",
      "cs": "Itálie",
      "pl": "Włochy",
      "hu": "Olaszország",
      "ro": "Italia",
      "bg": "Италия",
      "sk": "Taliansko",
      "sl": "Italija",
      "et": "Itaalia",
      "lv": "Itālija",
      "lt": "Italija",
      "hr": "Italija",
      "mt": "Italja",
      "ga": "An Iodáil",
      "is": "Ítalía"
    },
    // Add more countries here
  };
  
  // Define European language codes
  const europeanLanguageCodes = [
    "en", "fr", "de", "es", "it", "pt", "nl", "da", "sv", "fi",
    "el", "cs", "pl", "hu", "ro", "bg", "sk", "sl", "et", "lv",
    "lt", "hr", "mt", "ga", "is"
  ];
  
  /**
   * Translates a country name to the specified European language.
   * @param inputCountry - The name of the country in any language
   * @param targetLang - The target language code (e.g., 'fr' for French)
   * @returns The translated country name or an error message
   */
  export function translateCountry(inputCountry: string, targetLang: string): string {
    // Check if the target language is supported
    if (!europeanLanguageCodes.includes(targetLang)) {
      return inputCountry;
    }
  
    // Find the country object that contains the input name
    const country = Object.entries(countryTranslations).find(([, translations]) => 
      Object.values(translations).includes(inputCountry)
    );
  
    if (!country) {
        return inputCountry;
    }
  
    const [, translations] = country;
  
    // Return the translation in the target language, or English if not available
    return translations[targetLang] || translations["en"] || inputCountry;
  }