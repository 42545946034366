import * as React from "react";
import "./ValoriNutrizionaliStyle.scss";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { JsonObj } from "types";
import { Responsability } from "components/prodotto/responsability/Responsability";
import areAllPropertiesEmpty from "utils/PropertiesEmpty";

interface ValoriNutrizionaliUIProps {
  data: JsonObj;
  colorSchema: string;
}

const ValoriNutrizionaliUI: FC<ValoriNutrizionaliUIProps> = ({
  data,
  colorSchema,
}) => {
  const { t } = useTranslation();
  const {
    carbohydrateSugarg,
    carbohydrateg,
    energyKcal,
    saltg,
    energyKj,
    portionml,
    fiberg,
    fibergPortion,
    fatg,
    fatgPortion,
    fatSaturatesg,
    fatSaturatesgPortion,
    carbohydrateSugargPortion,
    carbohydrategPortion,
    energyKcalPortion,
    energyKjPortion,
    saltgPortion,
    showSaltTolerance,
    showFiber,
    monoUnsatFattyAcidsg,
    monoUnsatFattyAcidsgPortion,
    polyolsg,
    polyolsgPortion,
    polyunsatFattyAcidsg,
    polyunsatFattyAcidsgPortion,
    starchg,
    starchgPortion,
    showMonoUnsatFattyAcidsg,
    showPolyolsg,
    showPolyunsatFattyAcidsg,
    showStarchg,
    showSaltTolerancePerPortion,
    proteing,
    proteingPortion,
  } = data.nutritionalDeclaration ?? {};

  enum SaltToleranceEnum {
    salt = "< 0,01",
  }
  return (
    <div>
      {!data.product.hiddenNutritionalSection && (
        <>
          <div
            className={`dividerHeaderTitle template-sectiontitle-${colorSchema}`}
          >
            {t("nutritionalValues")}
          </div>
          <div className={"mainBlock"}>
            <table className={"valoriTable"}>
              <tr>
                <td
                  className={`title tdStyle template-vert-separator-${colorSchema} template-sectionsubtitle-${colorSchema}`}
                >
                  {t("gridLeftColumn")}
                </td>
                <td
                  className={`title per100mlCell tdStyle template-vert-separator-${colorSchema} template-sectionsubtitle-${colorSchema}`}
                >
                  {t("gridRightColumn")}
                </td>
                {portionml ? (
                  <td
                    className={`title perPortionCell tdStyle template-vert-separator-${colorSchema} template-sectionsubtitle-${colorSchema}`}
                  >
                    {`${t("for")} ${portionml}ml`}
                  </td>
                ) : null}
              </tr>

              <tr>
                <td
                  className={`tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                >
                  <div style={{ float: "left" }}>{t("energyLabel")}</div>
                </td>
                <td
                  className={`per100mlCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                >
                  {`${energyKj ? energyKj.toFixed(0) : "0"} kJ/${
                    energyKcal ? energyKcal.toFixed(0) : "0"
                  } kcal`}
                </td>
                {portionml ? (
                  <td
                    className={`perPortionCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                  >
                    {`${
                      energyKjPortion ? energyKjPortion.toFixed(0) : "0"
                    } kJ/${
                      energyKcalPortion ? energyKcalPortion.toFixed(0) : "0"
                    } kcal`}
                  </td>
                ) : null}
              </tr>
              <tr>
                <td
                  className={`tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                >
                  <div style={{ float: "left" }}>{t("fatLabel")}</div>
                </td>
                <td
                  className={`per100mlCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                >{`${fatg ? fatg.toFixed(2) : "0"} g`}</td>
                {portionml ? (
                  <td
                    className={`perPortionCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                  >{`${fatgPortion ? fatgPortion.toFixed(2) : "0"} g`}</td>
                ) : null}
              </tr>
              <tr>
                <td
                  className={`tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                >
                  <div style={{ float: "right" }}>{t("fatSaturatesLabel")}</div>
                </td>
                <td
                  className={`per100mlCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                >
                  {`${fatSaturatesg ? fatSaturatesg.toFixed(2) : "0"} g`}
                </td>
                {portionml ? (
                  <td
                    className={`perPortionCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                  >{`${
                    fatSaturatesgPortion ? fatSaturatesgPortion.toFixed(2) : "0"
                  } g`}</td>
                ) : null}
              </tr>
              {showMonoUnsatFattyAcidsg && (
                <tr>
                  <td
                    className={`tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                  >
                    <div style={{ float: "right" }}>
                      {t("monoUnsatFattyAcidsg")}
                    </div>
                  </td>
                  <td
                    className={`per100mlCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                  >{`${
                    monoUnsatFattyAcidsg ? monoUnsatFattyAcidsg.toFixed(2) : "0"
                  } g`}</td>
                  {portionml ? (
                    <td
                      className={`perPortionCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                    >
                      {`${
                        monoUnsatFattyAcidsgPortion
                          ? monoUnsatFattyAcidsgPortion.toFixed(2)
                          : "0"
                      } g`}
                    </td>
                  ) : null}
                </tr>
              )}
              {showPolyunsatFattyAcidsg && (
                <tr>
                  <td
                    className={`tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                  >
                    <div style={{ float: "right" }}>
                      {t("polyunsatFattyAcidsg")}
                    </div>
                  </td>
                  <td
                    className={`per100mlCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                  >{`${
                    polyunsatFattyAcidsg ? polyunsatFattyAcidsg.toFixed(2) : "0"
                  } g`}</td>
                  {portionml ? (
                    <td
                      className={`perPortionCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                    >
                      {`${
                        polyunsatFattyAcidsgPortion
                          ? polyunsatFattyAcidsgPortion.toFixed(2)
                          : "0"
                      } g`}
                    </td>
                  ) : null}
                </tr>
              )}
              <tr>
                <td
                  className={`tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                >
                  <div style={{ float: "left" }}>{t("carbohydrateLabel")}</div>
                </td>
                <td
                  className={`per100mlCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                >{`${carbohydrateg ? carbohydrateg.toFixed(2) : "0"} g`}</td>
                {portionml ? (
                  <td
                    className={`perPortionCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                  >
                    {`${
                      carbohydrategPortion
                        ? carbohydrategPortion.toFixed(2)
                        : "0"
                    } g`}
                  </td>
                ) : null}
              </tr>
              <tr>
                <td
                  className={`tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                >
                  <div style={{ float: "right" }}>
                    {t("carbohydrateSugarLabel")}
                  </div>
                </td>
                <td
                  className={`per100mlCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                >{`${
                  carbohydrateSugarg ? carbohydrateSugarg.toFixed(2) : "0"
                } g`}</td>
                {portionml ? (
                  <td
                    className={`perPortionCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                  >
                    {`${
                      carbohydrateSugargPortion
                        ? carbohydrateSugargPortion.toFixed(2)
                        : "0"
                    } g`}
                  </td>
                ) : null}
              </tr>
              {showPolyolsg && (
                <tr>
                  <td
                    className={`tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                  >
                    <div style={{ float: "right" }}>{t("polyolsg")}</div>
                  </td>
                  <td
                    className={`per100mlCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                  >{`${polyolsg ? polyolsg.toFixed(2) : "0"} g`}</td>
                  {portionml ? (
                    <td
                      className={`perPortionCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                    >
                      {`${
                        polyolsgPortion ? polyolsgPortion.toFixed(2) : "0"
                      } g`}
                    </td>
                  ) : null}
                </tr>
              )}
              {showStarchg && (
                <tr>
                  <td
                    className={`tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                  >
                    <div style={{ float: "right" }}>{t("starchg")}</div>
                  </td>
                  <td
                    className={`per100mlCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                  >{`${starchg ? starchg.toFixed(2) : "0"} g`}</td>
                  {portionml ? (
                    <td
                      className={`perPortionCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                    >
                      {`${starchgPortion ? starchgPortion.toFixed(2) : "0"} g`}
                    </td>
                  ) : null}
                </tr>
              )}
              {!showFiber && (
                <tr>
                  <td
                    className={`tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                  >
                    <div style={{ float: "left" }}>{t("fiberLabel")}</div>
                  </td>
                  <td
                    className={`per100mlCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                  >{`${fiberg ? fiberg.toFixed(2) : "0"} g`}</td>
                  {portionml ? (
                    <td
                      className={`perPortionCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                    >
                      {`${fibergPortion ? fibergPortion.toFixed(2) : "0"} g`}
                    </td>
                  ) : null}
                </tr>
              )}
              <tr>
                <td
                  className={`tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                >
                  <div style={{ float: "left" }}>{t("proteinLabel")}</div>
                </td>
                <td
                  className={`per100mlCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                >{`${proteing ? proteing.toFixed(2) : "0"} g`}</td>
                {portionml ? (
                  <td
                    className={`perPortionCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                  >{`${
                    proteingPortion ? proteingPortion.toFixed(2) : "0"
                  } g`}</td>
                ) : null}
              </tr>
              <tr>
                <td
                  className={`tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                >
                  <div style={{ float: "left" }}>{t("saltLabel")}</div>
                </td>
                <td
                  className={`per100mlCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                >{`${
                  showSaltTolerance
                    ? SaltToleranceEnum.salt
                    : saltg
                    ? saltg.toFixed(3)
                    : "0"
                } g`}</td>
                {portionml ? (
                  <td
                    className={`perPortionCell tdStyle template-vert-separator-${colorSchema} template-text-${colorSchema}`}
                  >
                    {`${
                      showSaltTolerancePerPortion
                        ? SaltToleranceEnum.salt
                        : saltgPortion
                        ? saltgPortion.toFixed(3)
                        : "0"
                    } g`}
                  </td>
                ) : null}
              </tr>
            </table>
          </div>
          <div className={`dividerStyle`}>
            <hr className={`template-horiz-separator-${colorSchema}`} />
          </div>
        </>
      )}
      {!data.product.hiddenRespConsumpSection &&
        !areAllPropertiesEmpty(data.responsibleConsumption) && (
          <Responsability
            data={data.responsibleConsumption}
            colorSchema={colorSchema}
          />
        )}
    </div>
  );
};

export default ValoriNutrizionaliUI;
