import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

export const convertFromRawToHtml = (text: string) => {
  let convertedText: string | null = null;

  try {
    const parsedContent = JSON.parse(text);
    if (Array.isArray(parsedContent.blocks) && parsedContent.blocks.length > 0) {
      const contentState = convertFromRaw(parsedContent as any);

      const htmlContent = stateToHTML(contentState, {
        defaultBlockTag: "p",
        entityStyleFn: (entity: any) => {
          const entityType = entity.get("type").toLowerCase();
          if (entityType === "link") {
            return {
              element: "a",
              attributes: {
                href: entity.getData().url,
              },
            };
          }
          return {};
        },
        blockStyleFn: (block: any) => {
          if (block.getType() === "unstyled") {
            return {
              element: "div",
              style: {
                whiteSpace: "pre-wrap",
              },
            };
          }
          return {};
        },
      });

      convertedText = htmlContent.replace(/\n/g, "<br>");
    } else {
      console.error("Parsed content is not valid.");
    }
  } catch (error) {
    console.error("Error parsing impressumContent:", error);
  }
  return convertedText;
};
