import React, { FC, useState } from "react";
import { JsonObj } from "../../../types";
import "./LegalNotesPreview.scss";
import "../../../ts-fonts.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { convertFromRawToHtml } from "utils/convertFromRawToHtml";
import { useTranslation } from "react-i18next";

interface LegalNotesProps {
  data: JsonObj;
  colorSchema: string;
}

const LegalNotesPreview: FC<LegalNotesProps> = ({ colorSchema, data }) => {
  const { t } = useTranslation();
  const [displayText, setDisplayText] = useState<boolean>(false);
  const impressumContent = data.impressums.impressums;
  let impressumText = impressumContent.find((item) => item.impressum_language_id === data.language.id)?.impressum_text as string;
  impressumText = convertFromRawToHtml(impressumText) ?? "";

  return (
    <>
      {impressumText && (
        <div className={`impressum-container template-vert-separator-${colorSchema}`}>
          <div className="div-title">
            <div className={impressumText ? `title template-icon-${colorSchema}` : `title template-messages-${{ colorSchema }}`}>
              {t("legalNotes")}
            </div>
            <FontAwesomeIcon
              icon={displayText ? faAngleUp : faAngleDown}
              className={impressumText ? `template-icon-${colorSchema}` : `template-disabled-${{ colorSchema }}`}
              size="lg"
              onClick={() => {
                if (impressumText) setDisplayText(!displayText);
              }}
            />
          </div>
          {impressumText !== "" && displayText && (
            <div className={`impressum-text template-messages-${colorSchema}`} dangerouslySetInnerHTML={{ __html: impressumText }}></div>
          )}
        </div>
      )}
    </>
  );
};

export default LegalNotesPreview;
