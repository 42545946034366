type ColorTranslations = {
    [key: string]: {
      [lang: string]: string;
    };
  };
  
  const colorTranslations: ColorTranslations = {
    "Bianco": {
      "it": "Bianco",
      "en": "White",
      "fr": "Blanc",
      "de": "Weiß",
      "es": "Blanco",
      "pt": "Branco",
      "nl": "Wit",
      "da": "Hvid",
      "sv": "Vit",
      "fi": "Valkoinen",
      "el": "Λευκό",
      "cs": "Bílé",
      "pl": "Białe",
      "hu": "Fehér",
      "ro": "Alb",
      "bg": "Бяло",
      "sk": "Biele",
      "sl": "Belo",
      "et": "Valge",
      "lv": "Balts",
      "lt": "Baltas",
      "hr": "Bijelo",
      "mt": "Abjad",
      "ga": "Bán",
      "is": "Hvítt"
    },
    "Rosso": {
      "it": "Rosso",
      "en": "Red",
      "fr": "Rouge",
      "de": "Rot",
      "es": "Rojo",
      "pt": "Vermelho",
      "nl": "Rood",
      "da": "Rød",
      "sv": "Röd",
      "fi": "Punainen",
      "el": "Κόκκινο",
      "cs": "Červené",
      "pl": "Czerwone",
      "hu": "Vörös",
      "ro": "Roșu",
      "bg": "Червено",
      "sk": "Červené",
      "sl": "Rdeče",
      "et": "Punane",
      "lv": "Sarkans",
      "lt": "Raudonas",
      "hr": "Crveno",
      "mt": "Aħmar",
      "ga": "Dearg",
      "is": "Rautt"
    },
    "Rosato": {
      "it": "Rosato",
      "en": "Rosé",
      "fr": "Rosé",
      "de": "Roséwein",
      "es": "Rosado",
      "pt": "Rosé",
      "nl": "Rosé",
      "da": "Rosévin",
      "sv": "Rosévin",
      "fi": "Roseeviini",
      "el": "Ροζέ",
      "cs": "Růžové",
      "pl": "Różowe",
      "hu": "Rozé",
      "ro": "Roze",
      "bg": "Розе",
      "sk": "Ružové",
      "sl": "Rosé",
      "et": "Rosé",
      "lv": "Rozā",
      "lt": "Rožinis",
      "hr": "Rosé",
      "mt": "Rosé",
      "ga": "Rosé",
      "is": "Rósavín"
    }
  };
 
  /**
   * Translates a color name to the specified European language.
   * @param color - The color to translate ("Bianco", "Rosso", or "Rosato")
   * @param targetLang - The target language code (e.g., 'fr' for French)
   * @returns The translated color name or the original input if translation is not available
   */
  export function translateColor(color: string, targetLang: string): string {
    const translations = colorTranslations[color];
    
    if (!translations) {
      return color; // Return original color if not found in translations
    }
  
    return translations[targetLang] || color; // Return translation or original color if language not found
  }