import * as React from "react";
import Cantina from "./Cantina/Cantina";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import "./MainStyle.scss";
import { RefObject, useEffect, useRef, useState } from "react";
import ValoriNutrizionali from "./ValoriNutrizionali/ValoriNutrizionali";
import ProdottoMain from "./prodotto/ProdottoMain";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { JsonObj, generic } from "types";
import getDisplayData from "utils/DisplayData";
import { useTranslation } from "react-i18next";
import { i18n, getLangsList, foreignStandardLng } from "i18n";
import Smaltimento from "./Smaltimento/Smaltimento";
import { Header } from "./prodotto/header/Header";
import "../ts-fonts.css";
import { TSLoader } from "./Loader/TSLoader";
import "../AppTemplates.scss";
import { selectedColorSchema } from "utils/SelectedColorSchema";
import LegalNotesPreview from "./Cantina/LegalNotesPreview/LegalNotesPreview";
interface MainProps {
  colorSchema: string;
  setColorSchema: Function;
}

const Main = (props: MainProps) => {
  const prodottoRef = useRef<HTMLDivElement>(null);
  const valoriNutrizionaliRef = useRef<HTMLDivElement>(null);
  const cantinaRef = useRef<HTMLDivElement>(null);
  const smaltimentoRef = useRef<HTMLDivElement>(null);
  const [data, setData] = useState<JsonObj>(getDisplayData(null));
  const [language, setLanguage] = useState<string>(
    i18n.language.split("-")[0] || foreignStandardLng
  );
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [display, setDisplay] = useState<{
    show: boolean;
    whereTo: string;
  }>({ show: false, whereTo: "" });

  const { t } = useTranslation();
  const { colorSchema, setColorSchema } = props;

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_WEBAPI_BASE_URL,
  });

  const errorMessage = (title: string, message: string) => {
    return (
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {message}
          </Typography>
        </Box>
      </Modal>
    );
  };

  const companyId = window.location.pathname.substring(1).split("/")[0];
  const guid = window.location.pathname.substring(1).split("/")[1];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const getData = () => {
    setDataLoading(true);
    if (guid && language) {
      let obj = {
        companyId: parseInt(companyId),
        guid,
      };
      let translateLanguageParam = "";
      if (language) {
        translateLanguageParam = `?lang=${language}`;
        const languageObj = getLangsList(language).find(
          (lang: generic) => lang.name === language
        );
        if (languageObj) {
          obj = Object.assign({ ...obj, languageid: languageObj.id });
        }
      }

      axiosInstance
        .post<JsonObj>(`api/details/getDetails${translateLanguageParam}`, obj)
        .then((response) => {
          const { data } = response;
          if (data) {
            setDataLoading(false);
            setData(getDisplayData(data));
            i18n.changeLanguage(language);
          }
        })
        .catch((error) => {
          return errorMessage(
            "Data can not be read",
            "Please contact the administrator!"
          );
        });
    }
  };

  setColorSchema(selectedColorSchema(data.product.templateId));

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  /*useEffect(() => {
    if (guid && !isNaN(parseInt(companyId))) {
      const obj = {
        guid,
        companyId: parseInt(companyId),
      };

      axiosInstance
        .post<generic[]>(`api/details/getLanguages`, obj)
        .then((response) => {
          const { data } = response;
          if (data) {
            //setSelectedLanguage(data[0].name);
            setLanguages(data);
          }
        })
        .catch((error) => {
          return errorMessage(
            "Data can not be read",
            "Please contact the administrator!"
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);*/

  const scrollToTarget = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop - 100,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (!display.show) {
      if (display.whereTo === "nValues") {
        scrollToTarget(valoriNutrizionaliRef);
      } else if (display.whereTo === "disposal") {
        scrollToTarget(smaltimentoRef);
      } else {
        scrollToTarget(prodottoRef)!;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [display]);

  if (!guid) {
    return errorMessage(t("qrScanError"), t("qrScanErrorMessage"));
  }

  return (
    <>
      {dataLoading ? (
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <TSLoader />
        </Box>
      ) : (
        <div>
          <Header
            languages={getLangsList(language)}
            setLanguage={setLanguage}
            language={language}
            colorSchema={colorSchema}
          />
          <div>
            <div className={`firstTabStyle ${display.show ? "" : "active"}`}>
              <div ref={prodottoRef}>
                <ProdottoMain data={data} colorSchema={colorSchema} language={language}/>
              </div>
              <div ref={valoriNutrizionaliRef}>
                <ValoriNutrizionali data={data} colorSchema={colorSchema} />
              </div>
              {!data.product.hiddenWasteDisposalSection && (
                <div ref={smaltimentoRef}>
                  <Smaltimento
                    data={data}
                    language={language}
                    colorSchema={colorSchema}
                  />
                </div>
              )}
              {data.product.showDetailsSection && data.impressums && (
                <LegalNotesPreview data={data} colorSchema={colorSchema} />
              )}
            </div>
            <div ref={cantinaRef}>
              <div className={`fade-in ${display.show ? "active" : ""}`}>
                <Cantina data={data} colorSchema={colorSchema} />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "80px" }}>
            <AppBar
              elevation={0}
              position="fixed"
              sx={{ top: "auto", bottom: 0 }}
            >
              {!data.product.hiddenNutritionalSection ||
              !data.product.hiddenWasteDisposalSection ||
              !data.product.showDetailsSection ? (
                <Toolbar
                  style={{
                    boxShadow: "none",
                    height: "80px",
                    width: "100%",
                    padding: 0,
                    justifyContent: "space-between",
                  }}
                  className={`template-footer-background-${colorSchema}`}
                >
                  <IconButton
                    onClick={() => {
                      if (!display.show) {
                        scrollToTarget(prodottoRef);
                      } else {
                        window.scrollTo(0, 0);
                        setDisplay({ show: false, whereTo: "prodotto" });
                      }
                    }}
                    className={`toolBarButtonStyle template-footer-background-${colorSchema} template-icon-${colorSchema}`}
                  >
                    <span
                      className="ts-icon-prodotto toolBarIconStyle"
                      style={{ fontSize: 30 }}
                    ></span>
                    <div
                      className={`iconDescriptionStyle template-footer-icon-${colorSchema}`}
                    >
                      {t("product")}
                    </div>
                  </IconButton>
                  {!data.product.hiddenNutritionalSection && (
                    <IconButton
                      onClick={() => {
                        if (!display.show) {
                          scrollToTarget(valoriNutrizionaliRef);
                        } else {
                          window.scrollTo(0, 0);
                          setDisplay({ show: false, whereTo: "nValues" });
                        }
                      }}
                      className={`toolBarButtonStyle template-footer-background-${colorSchema} template-icon-${colorSchema}`}
                    >
                      <span
                        className="ts-icon-valorinutrizionali toolBarIconStyle"
                        style={{ fontSize: 30 }}
                      ></span>
                      <div
                        className={`iconDescriptionStyle template-footer-icon-${colorSchema}`}
                      >
                        {t("nValues")}
                      </div>
                    </IconButton>
                  )}
                  {!data.product.hiddenWasteDisposalSection && (
                    <IconButton
                      onClick={() => {
                        if (!display.show) {
                          scrollToTarget(smaltimentoRef);
                        } else {
                          window.scrollTo(0, 0);
                          setDisplay({ show: false, whereTo: "disposal" });
                        }
                      }}
                      className={`toolBarButtonStyle template-footer-background-${colorSchema} template-icon-${colorSchema}`}
                    >
                      <span
                        className="ts-icon-smaltimento toolBarIconStyle"
                        style={{ fontSize: 30 }}
                      ></span>
                      <div
                        className={`iconDescriptionStyle template-footer-icon-${colorSchema}`}
                      >
                        {t("disposal")}
                      </div>
                    </IconButton>
                  )}
                  {!data.product.showDetailsSection && (
                    <IconButton
                      onClick={() => {
                        if (display.show) {
                          scrollToTarget(cantinaRef);
                        } else {
                          window.scrollTo(0, 0);
                          setDisplay({ show: true, whereTo: "details" });
                        }
                      }}
                      className={`toolBarButtonDetailsStyle template-footer-dtls-background-${colorSchema} template-icon-${colorSchema}`}
                    >
                      <span
                        className="ts-icon-dettagli toolBarIconStyle"
                        style={{ fontSize: 30 }}
                      ></span>
                      <div
                        className={`iconDescriptionStyle template-footer-icon-${colorSchema}`}
                      >
                        {t("details")}
                      </div>
                    </IconButton>
                  )}
                </Toolbar>
              ) : (
                ""
              )}
            </AppBar>
          </div>
        </div>
      )}
    </>
  );
};

export default Main;
