import * as React from "react";
import Azienda from "./Azienda/Azienda";
import { FC } from "react";
import { JsonObj } from "types";
import { ProductCharacteristics } from "components/prodotto/characteristics/ProductCharacteristics";
import AllegatiDaScaricare from "components/ValoriNutrizionali/AllegatiDaScaricare/AllegatiDaScaricare";
import RiconoscimentiEPremi from "components/ValoriNutrizionali/RiconoscimentiEPremi/RiconoscimentiEPremi";
import { Degustazione } from "components/prodotto/degustazione/Degustazione";
import areAllPropertiesEmpty from "utils/PropertiesEmpty";
import LegalNotesPreview from "./LegalNotesPreview/LegalNotesPreview";

interface CantinaProps {
  data: JsonObj;
  colorSchema: string;
}

const Cantina: FC<CantinaProps> = ({ data, colorSchema }) => {
  const { company } = data;

  return (
    <div>
      {!areAllPropertiesEmpty(data.characteristic) && (
        <>
          <ProductCharacteristics
            data={data.characteristic}
            colorSchema={colorSchema}
          />
          <div className={`dividerStyle`}>
            <hr className={`template-horiz-separator-${colorSchema}`} />
          </div>
        </>
      )}
      {!areAllPropertiesEmpty(data.tasting) && (
        <Degustazione data={data.tasting} colorSchema={colorSchema} />
      )}
      {!areAllPropertiesEmpty(data.prizesAndAwards) && (
        <RiconoscimentiEPremi
          data={data.prizesAndAwards}
          colorSchema={colorSchema}
        />
      )}
      {!areAllPropertiesEmpty(data.attachments) && (
        <AllegatiDaScaricare
          data={data.attachments}
          colorSchema={colorSchema}
        />
      )}
      {!areAllPropertiesEmpty(company) && (
        <Azienda data={company} colorSchema={colorSchema} />
      )}
      {data?.impressums && (
        <LegalNotesPreview data={data} colorSchema={colorSchema} />
      )}
    </div>
  );
};

export default Cantina;
