import * as React from "react";
import "./GeneralInfo.scss";
import { ProductType, CharacteristicsType, generic } from "types";
import StaticResourcesCDN, { ResourceType } from "utils/StaticResourcesCDN";
import {translateCountry} from "../../../locales/countryTranslator";
import {translateColor} from "../../../locales/colorTranslator";

interface GeneralInfoUIProps {
  data: ProductType;
  prodFeatures: CharacteristicsType;
  language: generic;
  companyName: string;
  colorSchema: string;
  langCode: string;
}

export const GeneralInfoUI = (props: GeneralInfoUIProps) => {
  const {
    imageShadow,
    image,
    name,
    appellationControlStatement,
    pdoPgiSymbols,
    traditionalTerms,
    producerCompanyOrCellar,
    country,
    category,
    color,
    biologic,
    countryIconCode,    
  } = props.data ?? {};
  const { alcoholicstrengthpercvolume, harvestyear } = props.prodFeatures;
  const { companyName, colorSchema, langCode } = props;

  const langId = props.language && props.language.name;

  const getProductInitialFeatures = (): string => {
    try {
      const alcoholVolume = alcoholicstrengthpercvolume
        ? `${alcoholicstrengthpercvolume}% vol`
        : "";

      const translatedCountry = translateCountry(country, langCode);
      const translatedColor = translateColor(color, langCode);
      const elements = [translatedCountry, category, translatedColor, alcoholVolume].filter(
        (element) => element !== null && element !== undefined && element !== ""
      );
      return elements.join(" - ");
    } catch (err) {
      console.error(err);
    }
    return "";
  };

  return (
    <div className="generalInfoMain">
      <div key={"mainLabel"} className={"mainLabel"}>
        {image && (
          <img
            style={{
              boxShadow: imageShadow
                ? "rgba(0, 0, 0, 0.5) 0px 0px 8px 2px"
                : "",
            }}
            className="mainLabelImg"
            src={image}
            alt="Product"
          />
        )}
      </div>
      <div
        key={"title"}
        className={`generalDiv Title template-title-${colorSchema}`}
        style={{ marginTop: 15 }}
      >
        {name}
      </div>
      <div key={"type_sub"} className={"generalDiv"}>
        <div className={`Subtitle template-subtitle-${colorSchema}`}>
          {[appellationControlStatement, harvestyear].filter(Boolean).join(" ")}
        </div>

        <div>
          {pdoPgiSymbols &&
            pdoPgiSymbols.map((symbolName: string) => {
              return (
                <img
                  className="typeIcon"
                  src={StaticResourcesCDN(
                    `${symbolName.replace("#LANG", `${langId}`)}.png`,
                    ResourceType.pdopgi
                  )}
                  alt={""}
                />
              );
            })}
        </div>
      </div>
      <div key={"type"} className={"generalDiv"}>
        <div className={`Subtitle template-subtitle-${colorSchema}`}>
          {traditionalTerms}
        </div>
      </div>
      <div key={"winery"} className={`generalDiv Heading`}>
        {producerCompanyOrCellar}
      </div>
      <div className="generalDiv iconsDiv">
        {countryIconCode && (
          <img
            className="countryFlag"
            src={StaticResourcesCDN(
              `${countryIconCode}.svg`,
              ResourceType.countries
            )}
            alt="country"
          />
        )}
        {biologic && (
          <img
            className="countryFlag"
            src={StaticResourcesCDN(
              "EU_Organic_Logo_Colour_rgb.gif",
              ResourceType.organic
            )}
            alt="organic"
          />
        )}
      </div>
      <div
        key="winery"
        className={`generalDiv winery template-company-title-${colorSchema}`}
      >
        {companyName}
      </div>
      <div className={`generalDiv Subheading template-subtitle-${colorSchema}`}>
        {getProductInitialFeatures()}
      </div>
    </div>
  );
};
