import * as React from "react";
import "./ProdottoMain.scss";
import ProdottoMainUI from "./ProdottoMainUI";
import { JsonObj } from "types";

interface ProdottoMainProps {
  data: JsonObj;
  colorSchema: string;
  language: string;
}

const ProdottoMain = (props: ProdottoMainProps) => {
  return <ProdottoMainUI data={props.data} colorSchema={props.colorSchema} language={props.language}/>;
};

export default ProdottoMain;
